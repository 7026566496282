import { Fragment } from "react";

export default function AppFooter() {

    return (
        <Fragment>
            <a href="https://www.silubi.com">silubi.com</a>&nbsp;&nbsp;
            <span className="blue-text">|</span>&nbsp;&nbsp;
            <a href="https://www.silubi.io">silubi.io</a>
        </Fragment>
    );
}