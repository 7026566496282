import React, { Fragment, useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import moment from "moment";
import Scanner from "../Components/Scanner/index";
import axios from "axios";

import { fetchProduct } from "../Store/Actions/goodsActions";
import { queryParams } from "../Store/GetSearch";
import { ScannerContext } from "../Store/ScanerContext";

import AppHeader from "../Components/UI/appHeader";
import AppFooter from "../Components/UI/appFooter";
import PayModal from "../Components/UI/PayModal";
import Map from "../Components/Visualisations/Map";
import Logo from "../Assets/images/silubi-symbol.svg";

export default function TracePage() {
	const dispatch = useDispatch();
	const { userData } = useSelector((state) => state.userReducer);
	const { goodsExchanged, productData } = useSelector((state) => state.goodsReducer);
	const { search } = useLocation();
	const { isScanning, setIsScanning, camError, scannedItems, setScannedItems } = useContext(ScannerContext);
	const [geolocation, setGeolocation] = useState(null);
	const [clickedStep, setClickedStep] = useState(null);
	const [timeData, setData] = useState(null);
	const [mapData, setMapData] = useState(null);
	const [error, setError] = useState(null);

	const [isLoading, setIsLoading] = useState(true);
	const [isPayModal, setIsPayModal] = useState(false);
	// eslint-disable-next-line

	const prodID = queryParams(search, "gSerial");

	//Fetch the timeline of the product
	useEffect(() => {
		const cancelTokenSource = axios.CancelToken.source();
		if (navigator.onLine) {
			if (prodID) {
				dispatch(fetchProduct(prodID));
				axios
					.get(`${process.env.REACT_APP_AWS_API_URL}/timeline/fetch_timeline?gSerial=${prodID}&action=fetch_timeline`, {
						headers: { "x-api-key": process.env.REACT_APP_AWS_API_KEY },
					})
					.then(({ data }) => {
						if (data.length === 0) {
							throw new Error("No item data found");
						} else {
							const g = [];
							const f = [];
							data.forEach((el) => {
								if (el._fields[0]) {
									g.push(el._fields[0]);
									f.push(el._fields[0].bizMarker);
								}
							});

							setMapData(f);
							setData(g);
							setError(null);
							setIsLoading(false);
						}
					})
					.catch((error) => {
						setError(error);
						setIsLoading(false);
					});
			} else {
				setError({ message: "Please scan an item" });
				setIsLoading(false);
			}
		} else {
			setError({ message: "Please check your internet" });
			setIsLoading(false);
		}
		return () => {
			cancelTokenSource.cancel();
		};

		// eslint-disable-next-line
	}, [prodID, goodsExchanged, navigator.onLine]);

	useEffect(() => {
		navigator.geolocation.getCurrentPosition((res) => {
			setGeolocation(`${res.coords.latitude}, ${res.coords.longitude}`);
		});
	}, []);

	return (
		<div className="App-body">
			<AppHeader />

			{isLoading ? (
				<div className="content">
					<div className="messaging">Retrieving Data...</div>
					<div className="button-area">
						<div className="button">
							<div className="loading-button">
								<div className="ring">
									<img src={Logo} alt="Sil" />
								</div>
							</div>
						</div>
					</div>
				</div>
			) : error && !isScanning ? (
				<div className="content">
					<div className="messaging">{error.message}</div>
					<div className="button-area">
						<div className="button">
							<div
								className="main-button"
								onClick={() => {
									setIsScanning(true);
									setScannedItems([]);
								}}
							>
								<div className="ring">
									<span className="material-icons">qr_code_scanner</span>
								</div>
							</div>
							<span className="main-button-title">Silubi&trade; Scan</span>
						</div>
					</div>
				</div>
			) : isScanning ? (
				<div className="content">
					<Scanner fromTrace={true} />
				</div>
			) : (
				productData && (
					<div
						className="content"
						onClick={(e) => {
							if (e.target.className === "result-vis") setClickedStep(null); // Set the clicked marker to its normal state
						}}
					>
						{/* ------------------------------ Display the top section content (the last step of the timeline) ------------------------------ */}
						<div className="result">
							<div className="messaging">
								<div>
									{/* <pre> {JSON.stringify(data, null, 2)}</pre> */}
									<p className="product">
										{productData[0].title} ({productData[0].weight.low}
										{productData[0].unit})
										<br />
										<span className="menuSmallText">
											<span className="grey-text">(Item: {productData[0].id.slice(74)})</span>
										</span>
									</p>

									<p className="location-detail">
										{productData[0].city}
										{", "}
										{productData[0].country}
									</p>
								</div>
							</div>

							{productData[0].status !== "In Transit" ? (
								!userData ? (
									<div className="price">&#7785;5.65</div>
								) : (
									<>
										{!productData[0].aSerial.includes(userData.aSerial) ? (
											<div className="price">&#7785;5.65</div> // <------------------------- Pricing data to go here.
										) : (
											<div className="price">Item is in your inventory</div>
										)}
									</>
								)
							) : (
								<div className="price">Item in transit</div>
							)}
							{/* ------------------------------ End of the top section ------------------------------ */}

							{productData[0].status !== "In Transit" ? (
								!userData ? (
									<div className="button-area">
										<div
											className="button"
											onClick={() => {
												setIsPayModal(true);
											}}
										>
											<div className="main-button">
												<div className="ring">
													<img src={Logo} alt="Sil" />
												</div>
											</div>
											<span className="main-button-title">Pay with Silubi&trade;</span>
										</div>
									</div>
								) : (
									<div className="button-area">
										{!productData[0].aSerial.includes(userData.aSerial) ? (
											<div
												className="button"
												onClick={() => {
													setIsPayModal(true);
												}}
											>
												<div className="main-button">
													<div className="ring">
														<img src={Logo} alt="Sil" />
													</div>
												</div>
												<span className="main-button-title">Pay with Silubi&trade;</span>
											</div>
										) : (
											<div className="button">
												<a href={`/inventory`} className="main-button">
													<div className="ring">
														<span className="material-icons">list</span>
													</div>
												</a>
												<span className="main-button-title">View Inventory</span>
											</div>
										)}
									</div>
								)
							) : (
								<div className="button-area">
									<div className="button">
										<div className="wallet-button">
											<div className="warning-ring">
												<span className="material-icons">local_shipping</span>
											</div>
										</div>
									</div>
								</div>
							)}

							{/* ------------------------------ Timeline section ------------------------------ */}
							<div className="result-vis">
								<span className="grey-text">Check your product journey</span>
								<div className="map-vis">
									<Map clicked={clickedStep} mapData={mapData} />
								</div>
								<div className="timeline-vis">
									{timeData &&
										timeData.map((el, index) => (
											<Fragment key={index}>
												<div
													className={index === 0 ? "timeline-start" : index === timeData.length - 1 ? "timeline-stop" : "timeline-node"}
													onClick={() => {
														if (el.bizActivity === "In Transit") return;
														if (clickedStep === el) setClickedStep({ bizActivityID: el.bizActivityID, action: "repeated" });
														else setClickedStep(el);
													}}
												/>
												<div className="timeline-step">
													<p className="timeline-agent">
														{el.bizActivity === "Exchanged" || el.bizActivity === "In Transit" || el.bizActivity === "Commissioned"
															? el.bizAgent
															: el.bizAgent2}
													</p>
													<p className="timeline-data">
														<>
															{el.bizActivity}
															<span className="grey-text"> | </span>
														</>

														{el.bizActivity === "In Transit" ? null : (
															<>
																{el.bizLocationCountry}
																<span className="grey-text"> | </span>
															</>
														)}

														{el.bizActivity === "In Transit" ? null : (
															<>
																{el.bizLocationCity}
																<span className="grey-text"> | </span>
															</>
														)}

														{moment(el.bizTime).format("DD/MM/YYYY")}
													</p>
												</div>
											</Fragment>
										))}
								</div>
							</div>
						</div>
					</div>
				)
			)}

			<div className={(error && !isScanning) || isLoading || camError ? "error-footer" : "result-footer"}>
				<AppFooter />
			</div>

			{isPayModal && productData ? (
				<PayModal
					geolocation={geolocation}
					query={queryParams(search, "productID")}
					productData={productData[0]}
					closeFunction={() => {
						setIsPayModal(false);
					}}
				/>
			) : null}
		</div>
	);
}
