import axios from "axios";
import { exchange_goods } from "../Prov_Templates/exchange_goods";
import { transport_goods } from "../Prov_Templates/transport_goods";
export const fetchGoods = (aSerial) => {
	const cancelTokenSource = axios.CancelToken.source();
	return (dispatch) => {
		axios
			.get(`${process.env.REACT_APP_AWS_API_URL}/goods/fetch_goods?aSerial=${aSerial}&action=fetch_goods`, {
				headers: { "x-api-key": process.env.REACT_APP_AWS_API_KEY },
			})
			.then(({ data, status }) => {
				if (status === 200) {
					const result = [];
					data.forEach((record) => {
						result.push(record._fields[0]);
					});

					dispatch({ type: "GOODS_DATA_RECEIVED", result });
				} else if (status === 400 || status === 504) throw new Error("Network Error");
				else throw new Error("You have no products in inventory");
			})
			.catch((error) => {
				dispatch({ type: "GOODS_ERROR", error });
			});
	};
};

export const fetchProduct = (gSerial) => {
	return (dispatch) => {
		axios
			.get(`${process.env.REACT_APP_AWS_API_URL}/goods/fetch_good?gSerial=${gSerial}&action=fetch_good`, {
				headers: { "x-api-key": process.env.REACT_APP_AWS_API_KEY },
			})
			.then(({ data, status }) => {
				if (status === 504) throw new Error("It took too long to get data,please try again");
				if (status === 400) throw new Error("There was an error during fetching product data");
				if (status === 200) {
					if (data.length === 0) throw new Error(`No data found`);
					const result = data[0]._fields;

					if (!result[0].country || !result[0].city) {
						const coordinates = result[0].geolocation.split(",");

						axios
							.get(
								`https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates[1]},${coordinates[0]}.json?access_token=pk.eyJ1IjoicHIxZGUiLCJhIjoiY2twZnAwamZkMGszODJ3cmllYTR6MmNtdyJ9.gprPfGqXmOufIdWeV7fK7g`
							)
							.then(({ data }) => {
								result[0].country = data.features[5].text;
								result[0].city = data.features[2].text;
							})
							.catch((error) => {
								dispatch({ type: "PRODUCT_DATA_ERROR", error });
							});
					}

					return result;
				}
			})
			.then((result) => {
				console.log("Sending result to user");
				dispatch({ type: "PRODUCT_DATA_RECEIVED", result });
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type: "PRODUCT_DATA_ERROR", error });
			});
	};
};

export const exchangeGoods = (prov_json, token, action, prov_json2) => {
	console.log(`Handling ${action} activity`);

	return (dispatch) => {
		exchange_goods(prov_json, token)
			.then((res) => {
				if (res.error) throw new Error(`Error posting data to Silubi.io. ${res.error}`);

				let result;
				if (action === "exchanging") {
					transport_goods(prov_json2, token)
						.then((res) => {
							if (res.error) throw new Error(`Error posting data to Silubi.io. ${res.error}`);
							result = true;
							dispatch({ type: "GOODS_EXCHANGED", result });
							setTimeout(() => {
								result = false;
								dispatch({ type: "GOODS_EXCHANGED", result });
							}, 2000);
						})
						.catch((error) => {
							dispatch({ type: "GOODS_ERROR", error });
						});
				}
				if (action === "collection") {
					transport_goods(prov_json2, token)
						.then((res) => {
							if (res.error) throw new Error(`Error posting data to Silubi.io. ${res.error}`);

							dispatch({ type: "GOODS_PICKED_UP" });
						})
						.catch((err) => {
							dispatch({ type: "GOODS_PICKED_UP_ERROR", err });
						});
				}
				if (action === "dropoff") {
					dispatch({ type: "GOODS_DROPPED_OFF" });
				}
			})
			.catch((err) => {
				if (action === "dropoff") {
					dispatch({ type: "GOODS_DROPPED_OFF_ERROR", err });
				}
				if (action === "collection") {
					dispatch({ type: "GOODS_PICKED_UP_ERROR", err });
				}
				if (action === "exchanging") {
					dispatch({ type: "GOODS_EXCHANGED_ERROR", err });
				}
			});
	};
};
export const transportGoods = (transportedGoods, token) => {
	return (dispatch) => {
		transport_goods(transportedGoods, token)
			.then((res) => {
				if (!res || res.error) throw new Error(`Error posting data to Silubi.io. ${res.error}`);
			})
			.catch((error) => {
				dispatch({ type: "GOODS_TRANSPORT_ERROR", error });
				setTimeout(() => {
					error = null;
					dispatch({ type: "GOODS_TRANSPORT_ERROR", error });
				}, 2000);
			});
	};
};
