import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import axios from "axios";

import AppHeader from "../Components/UI/appHeader";
import AppFooter from "../Components/UI/appFooter";
import DropOffForm from "../Components/Form/DropOffForm";
import PickUpForm from "../Components/Form/PickUpForm";
import ExchangeForm from "../Components/Form/ExchangeForm";
import Scanner from "../Components/Scanner/index";
import Logo from "../Assets/images/silubi-symbol.svg";
import CloseIcon from "../Assets/images/close-icon.svg";

import { ScannerContext } from "../Store/ScanerContext";

export default function ScanResultPage() {
	const { scannedItems, setScannedItems, isScanning, setIsScanning } = useContext(ScannerContext);
	const { goodsData } = useSelector((state) => state.goodsReducer);
	const history = useHistory();

	//******** Setting State ********
	const [productData, setProductData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isDropingOff, setIsDroppingOff] = useState(false);
	const [isPickingUp, setIsPickingUp] = useState(false);
	const [isFetchingData, setIsFetchingData] = useState(false);
	const [isExchanging, setIsExchanging] = useState(false);
	//******** Functions ********
	function handleClear(e) {
		setScannedItems(scannedItems.filter((f) => f !== e.id));
		setProductData(productData.filter((f) => f.id !== e.id));
	}
	useEffect(() => {
		if ((productData.length === 0 && scannedItems.length === 0) || !goodsData) history.push("/inventory");
		// eslint-disable-next-line
	}, [productData, scannedItems]);

	//Fetch data of the scanned items
	useEffect(() => {
		const cancelTokenSource = axios.CancelToken.source();
		if (!isScanning && scannedItems.length > 0) {
			setIsFetchingData(true);

			//Pass existing product data to a local variable
			let g = productData;
			let f = scannedItems;
			//Check scanned items

			f.forEach((item) => {
				//If item data has already been received, return

				if (item.includes("https://dev2.silubi.io/prov-wrt/provapi/resources/goods/p/lot/")) {
					axios
						.get(`${process.env.REACT_APP_AWS_API_URL}/goods/fetch_good?lotSerial=${item.slice(76)}&action=fetch_good_by_lot`, {
							headers: { "x-api-key": process.env.REACT_APP_AWS_API_KEY },
						})
						.then((res) => {
							res.data.forEach((record) => {
								const h = goodsData.find((g) => g.id === record._fields[0].id);

								if (h) record._fields[0].action = "dropOff";
								else if (record._fields[0].status === "In Transit") record._fields[0].action = "inTransit";
								else record._fields[0].action = "pickUp";
								g.push(record._fields[0]);

								f.push(record._fields[0].id);
							});
							const o = f.filter((d) => !d.includes("https://dev2.silubi.io/prov-wrt/provapi/resources/goods/p/lot/"));

							//If number of scanned items are equal with the received data proceed.
							if (o.length === g.length) {
								setScannedItems(o);
								setProductData(g);
								setIsFetchingData(false);
							}
						})
						.catch((error) => console.log(error));
				} else if (item.includes("https://dev2.silubi.io/prov-wrt/provapi/resources/goods/p/g/")) {
					const h = g.find((a) => a.id === item || a.lotSerial === item.slice(76));

					if (h) {
						setIsFetchingData(false);
						return;
					} else {
						//Else get product data for the scanned item

						axios
							.get(`${process.env.REACT_APP_AWS_API_URL}/goods/fetch_good?gSerial=${item}&action=fetch_good`, {
								headers: { "x-api-key": process.env.REACT_APP_AWS_API_KEY },
							})
							.then(({ data, status }) => {
								if (status === 200) {
									console.log(goodsData);
									//If there's no data for the scanned item
									if (data.length === 0) {
										g.push({ id: item, title: "Item doesn't exist", weight: null, unit: null, type: null, error: true });
									} else {
										//Othwerwise proceed and add it to the lot in the local variable
										const h = goodsData.find((g) => g.id === data[0]._fields[0].id);
										if (h) data[0]._fields[0].action = "dropOff";
										else if (data[0]._fields[0].status === "In Transit") data[0]._fields[0].action = "inTransit";
										else data[0]._fields[0].action = "pickUp";

										g.push(data[0]._fields[0]);
									}
									const o = f.filter((d) => !d.includes("https://dev2.silubi.io/prov-wrt/provapi/resources/goods/p/l/"));
									//If number of scanned items are equal with the received data proceed.
									if (o.length === g.length) {
										setScannedItems(o);
										setProductData(g);
										setIsFetchingData(false);
									}
								} else if (status === 400) throw new Error("Network Error");
								else throw new Error("No data found");
							})
							.catch((error) => {
								console.log(error);
								// setError(error.message);
							});
					}
				}
			});
		}
		return () => {
			setIsFetchingData(false);
			cancelTokenSource.cancel();
		};
		// eslint-disable-next-line
	}, [isScanning, goodsData]);

	return (
		<div className="App-body">
			<AppHeader />

			<div className="content">
				{!isScanning ? ( // -------------------- Check if user pressed the scanner button, If scanning activity is not active show button. --------------------
					<>
						<div className="messaging">
							{isFetchingData ? "Loading Scan Result..." : productData.length > 0 ? "Scan Result" : "Please scan an item"}

							{productData.filter((item) => item.action === "dropOff").length === productData.filter((x) => !x.error).length
								? ""
								: productData.filter((item) => item.action === "pickUp").length === productData.filter((x) => !x.error).length && ""}
						</div>

						<div className="button-area">
							<>
								{isFetchingData ? ( //Await the fetching data
									<div className="button">
										<div className="loading-button">
											<div className="ring">
												<img src={Logo} alt="Sil" />
											</div>
										</div>
									</div>
								) : (
									scannedItems.length > 0 && (
										<>
											{productData.filter((item) => item.action === "dropOff").length === scannedItems.length ? ( //If scanned items are in the inventory of the user show "DROPOFF" button
												<div className="button">
													<div
														className="main-button"
														onClick={() => {
															setIsDroppingOff(true);
														}}
													>
														<div className="ring">
															<span className="material-icons">archive</span>
														</div>
													</div>
													<span className="main-button-title">Drop Off</span>
												</div>
											) : productData.filter((item) => item.action === "pickUp").length === scannedItems.length ? (
												//else "PICKUP" button
												<div className="button">
													<div
														className="main-button"
														onClick={() => {
															setIsPickingUp(true);
														}}
													>
														<div className="ring">
															<span className="material-icons">unarchive</span>
														</div>
													</div>
													<span className="main-button-title">Pickup</span>
												</div>
											) : productData.filter((item) => item.action === "inTransit").length === scannedItems.length ? (
												//else "PICKUP" button
												<div className="button">
													<div className="wallet-button">
														<div className="warning-ring">
															<span className="material-icons">local_shipping</span>
														</div>
													</div>
												</div>
											) : productData.find((item) => item.error) ? null : (
												//else Should not be allowed to perform any actions
												<div className="button">
													<div
														className="main-button"
														onClick={() => {
															setIsExchanging(true);
														}}
													>
														<div className="ring">
															<span className="material-icons">unarchive</span>
														</div>
													</div>
													<span className="main-button-title">Exchange</span>
												</div>
											)}

											<div className="button">
												<div
													className="main-button"
													onClick={() => {
														setIsScanning(true);
													}}
												>
													<div className="ring">
														<span className="material-icons">qr_code_scanner</span>
													</div>
												</div>
												<span className="main-button-title">Scan Another</span>
											</div>
										</>
									)
								)}
							</>

							{/* // -------------------- Allow user to scan & add more items in this session -------------------- */}
						</div>

						<div className="result-vis">
							<>
								{isDropingOff && (
									<DropOffForm
										closeWindow={() => {
											setIsDroppingOff(false);
										}}
										setIsLoading={(e) => {
											setIsLoading(e);
										}}
										isLoading={isLoading}
										emptyProductData={() => {
											setProductData([]);
										}}
										productData={productData}
									/>
								)}
								{isPickingUp && (
									<PickUpForm
										closeWindow={() => {
											setIsPickingUp(false);
										}}
										emptyProductData={() => {
											setProductData([]);
										}}
										setIsLoading={(e) => {
											setIsLoading(e);
										}}
										isLoading={isLoading}
										productData={productData}
									/>
								)}
								{isExchanging && (
									<ExchangeForm
										closeWindow={() => {
											setIsDroppingOff(false);
										}}
										setIsLoading={(e) => {
											setIsLoading(e);
										}}
										isLoading={isLoading}
										emptyProductData={() => {
											setProductData([]);
										}}
										productData={productData}
									/>
								)}
							</>

							{!isFetchingData && !isScanning ? ( // -------------------- Show scanned items here --------------------
								<>
									<span className="grey-text">You have scanned these items:</span>
									<div className="inventory-vis">
										<table className="inventoryTable">
											<tbody>
												<tr className="inventoryTableHeader">
													<th className="inventoryTableHeadIcon" width="25px"></th>
													<th className="inventoryTableHead">Item</th>
													<th className="inventoryTableHead">Title</th>
													<th className="inventoryTableHead">Size</th>
													<th className="inventoryTableHead">Type</th>
													<th className="inventoryTableHeadIcon" width="25px">
														<img
															src={CloseIcon}
															alt=""
															className="closeIcon"
															onClick={() => {
																setScannedItems([]);
																setProductData([]);
															}}
														/>
													</th>
												</tr>

												{productData.map((item) => (
													<tr className="inventoryTableRow" key={item.id}>
														<td className="inventoryTableDataIcon">
															{item.action === "pickUp" ? (
																<span className="grey-text">
																	<span className="material-icons">unarchive</span>
																</span>
															) : item.action === "dropOff" ? (
																<span className="teal-text">
																	<span className="material-icons">archive</span>
																</span>
															) : item.action === "inTransit" ? (
																<span className="teal-text">
																	<span className="material-icons">local_shipping</span>
																</span>
															) : (
																""
															)}
														</td>
														<td className="inventoryTableData">{item.id.slice(74)}</td>
														<td className="inventoryTableData">{item.title}</td>
														<td className="inventoryTableData">
															{item.weight && item.weight.low}
															{item.unit}
														</td>
														<td className="inventoryTableData">{item.type}</td>
														<td className="inventoryCloser">
															<span
																className="material-icons-round"
																onClick={() => {
																	handleClear(item);
																}}
															>
																disabled_by_default
															</span>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</>
							) : (
								scannedItems.length === 0 && <span className="grey-text">Scanned items will apear here.</span>
							)}
						</div>
					</>
				) : (
					<Scanner fromScanResult={true} />
				)}
			</div>

			<div className={isLoading ? "error-footer" : "result-footer"}>
				<AppFooter />
			</div>
		</div>
	);
}
