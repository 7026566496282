import axios from "axios";

export const fetchUser = (user, token) => {
	return (dispatch) => {
		axios
			.get(`${process.env.REACT_APP_AWS_API_URL}/user/fetch_user?userID=${user}&action=fetch_user`, {
				headers: { "x-api-key": process.env.REACT_APP_AWS_API_KEY },
			})
			.then(({ data }) => {
				const result = data[0]._fields[0];

				dispatch({ type: "USER_DATA_RECEIVED", result, token });
			})
			.catch((error) => {
				dispatch({ type: "USER_DATA_ERROR", error, token });
			});
	};
};
export const fetchLisOfUsers = (userID) => {
	return (dispatch) => {
		axios
			.get(`http://localhost:9000/api/microservices/user/users_list?userID=${userID}`)
			.then(({ data }) => {
				dispatch({ type: "USERS_LIST_DATA_RECEIVED", data });
			})
			.catch((error) => {
				dispatch({ type: "USERS_LIST_DATA_ERROR", error });
			});
	};
};
export const setUserLocation = (coordinates) => {
	return (dispatch) => {
		if (coordinates) dispatch({ type: "USER_LOCATION", coordinates });
	};
};
