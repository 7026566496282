import React, { useState, useEffect, Fragment, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import AppHeader from "../Components/UI/appHeader";
import AppFooter from "../Components/UI/appFooter";
import QRModal from "../Components/UI/QRModal";
import Scanner from "../Components/Scanner/index";

import { transportGoods, fetchGoods } from "../Store/Actions/goodsActions";
import { ScannerContext } from "../Store/ScanerContext";

export default function InventoryPage() {
	const dispatch = useDispatch();

	const { isScanning, setIsScanning, camError } = useContext(ScannerContext);
	const { userData, token } = useSelector((state) => state.userReducer);
	const { goodsDroppedOff, goodsPickedUp, goodsData, goodsDataError, goodsTransportedError } = useSelector((state) => state.goodsReducer);
	const [isLoading, setIsLoading] = useState(false);
	const [isQRModal, setIsQRModal] = useState(null);

	const [lotID, setLotID] = useState(null);

	//fetch products for the inventory of the user
	useEffect(() => {
		if ((userData && goodsPickedUp) || (userData && goodsDroppedOff)) setIsLoading(false);
		if (userData) dispatch(fetchGoods(userData.aSerial));
		// eslint-disable-next-line
	}, [userData, goodsPickedUp, goodsDroppedOff]);

	//Capture a geolocation of the goods that in transit
	useEffect(() => {
		let interval;

		if (userData && goodsData) {
			const MINUTE_MS = 3 * 60 * 1000;
			const goods = [];
			//check if goods are in transit
			goodsData.forEach((el) => {
				if (el.status === "In Transit")
					goods.push({
						isA: "goods_transport",
						aserial: userData.aSerial,
						quantity: parseInt(el.weight.low),
						unit: el.unit,
						gserial: el.id.slice(56),
						geolocation: null,
						transportType: "Driving",
						goodsType: el.type,
					});
			});

			if (goods.length > 0) {
				console.log("You have goods that are in transit :: " + goods.map((good) => good.gserial));

				interval = setInterval(() => {
					//Get the geolocation of the ping
					navigator.geolocation.getCurrentPosition(async (res) => {
						const coordinates = await `${res.coords.longitude}, ${res.coords.latitude}`;
						goods.forEach((e) => {
							e.geolocation = coordinates;
						});
						//Run the prov template of the transportation
						console.log("Running the prov template");
						dispatch(transportGoods(goods, token));
					});
				}, MINUTE_MS);
			}
		}

		return () => clearInterval(interval); // This represents the unmount function, to prevent memory leaks.
		// eslint-disable-next-line
	}, [userData, goodsData]);

	return (
		<div className="App-body">
			<AppHeader />
			{isScanning ? (
				<div className="content">
					<Scanner />
				</div>
			) : (
				<div className="content">
					<div className="messaging">Manage inventory</div>
					<div className="button-area">
						<div className="button">
							<div
								className="main-button"
								onClick={() => {
									setIsScanning(true);
								}}
							>
								<div className="ring">
									<span className="material-icons">qr_code_scanner</span>
								</div>
							</div>
							<span className="main-button-title">Silubi&trade; Scan</span>
						</div>
					</div>
					<div className="result-vis">
						{navigator.onLine ? (
							goodsData && goodsData.length > 0 ? ( // -------------------- Show the content of the inventory here --------------------
								<>
									<span className="grey-text">Inventory</span>
									<div className="inventory-vis">
										{lotID ? (
											<Fragment>
												<table className="inventoryTable">
													<tbody>
														<tr className="inventoryTableLotMarker">
															<td className="inventoryTableLotMarkerData" colSpan={5}>
																<span className="grey-text">Viewing lot:</span> {lotID}
															</td>
															<td className="inventoryCloser">
																<span
																	className="material-icons-round"
																	onClick={() => {
																		setLotID(null);
																	}}
																>
																	disabled_by_default
																</span>
															</td>
														</tr>

														<tr className="inventoryTableHeader">
															<th className="inventoryTableHead">Item</th>
															<th className="inventoryTableHead">Title</th>
															<th className="inventoryTableHead">Size</th>
															<th className="inventoryTableHead">Registered</th>
															<th className="inventoryTableHead">QR</th>
															<th className="inventoryTableHeadIcon" width="25px">
																<span className="material-icons">local_shipping</span>
															</th>
														</tr>

														{goodsData
															.filter((item) => item.lotSerial === lotID)
															.map((el, index) => (
																<tr className="inventoryTableRow" key={index}>
																	<td className="inventoryTableData">
																		<Link to={`/trace?gSerial=${el.id}`}>{el.id.slice(74)}</Link>
																	</td>
																	<td className="inventoryTableData">
																		<span>{el.title}</span>
																	</td>
																	<td className="inventoryTableData">
																		{el.weight.low}
																		{el.unit}
																	</td>
																	<td className="inventoryTableData">{new Date(el.date).toLocaleString()}</td>

																	<td className="inventoryTableData">
																		<Link to={"#"}>
																			<span
																				className="material-icons"
																				onClick={() => {
																					setIsQRModal(el.id);
																				}}
																			>
																				qr_code
																			</span>
																		</Link>
																	</td>
																	<td className="inventoryTableData">{el.status === "In Transit" && <span className="material-icons">local_shipping</span>}</td>
																</tr>
															))}
													</tbody>
												</table>
											</Fragment>
										) : (
											<table className="inventoryTable">
												<tbody>
													<tr className="inventoryTableHeader">
														<th className="inventoryTableHead">SKU</th>
														<th className="inventoryTableHead">Lot</th>
														<th className="inventoryTableHead">Type</th>
														<th className="inventoryTableHead">#</th>
														<th className="inventoryTableHead" width="25px"></th>
													</tr>

													{goodsData
														.filter((item, index, self) => index === self.findIndex((y) => y.lotSerial === item.lotSerial))
														.map((el, index) => (
															<tr className="inventoryTableRow" key={index}>
																<td className="inventoryTableData">{el.skuSerial}</td>
																<td className="inventoryTableData">
																	<a
																		href="#"
																		id={el.lotSerial}
																		onClick={(e) => {
																			setLotID(e.target.id);
																		}}
																	>
																		{el.lotSerial}
																	</a>
																</td>
																<td className="inventoryTableData">{el.type}</td>
																<td className="inventoryTableData">{goodsData.filter((x) => x.lotSerial === el.lotSerial).length}</td>
																<td className="inventoryTableData">
																	<a>
																		<span
																			className="material-icons"
																			onClick={() => {
																				setIsQRModal(`https://dev2.silubi.io/prov-wrt/provapi/resources/goods/p/lot/${el.lotSerial}`);
																			}}
																		>
																			qr_code
																		</span>
																	</a>
																</td>
															</tr>
														))}
												</tbody>
											</table>
										)}
									</div>
								</>
							) : goodsDataError || goodsTransportedError ? (
								<span className="orange-text">{goodsDataError.message || goodsTransportedError.message}</span>
							) : goodsData && goodsData.length === 0 ? (
								<div> You have no goods in your inventory </div>
							) : (
								<span className="grey-text">
									<CircularProgress
										size={12}
										sx={{
											top: "50%",
											left: "50%",
											// marginTop: "-12px",
											//marginLeft: "12px",
										}}
									/>
									&nbsp;Loading inventory data
								</span>
							)
						) : (
							<span className="grey-text">&nbsp;No Internet Connection</span>
						)}
					</div>

					{isQRModal && (
						<QRModal
							url={isQRModal}
							closeFunction={() => {
								setIsQRModal(null);
							}}
						/>
					)}
				</div>
			)}
			<div className={camError || isLoading ? "error-footer" : "result-footer"}>
				<AppFooter />
			</div>
		</div>
	);
}
