import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./Store/Reducers/rootReducer";
import { Provider } from "react-redux";

import "./Assets/styles/index.css";
import "./Assets/styles/partials/buttons.css";
import "./Assets/styles/partials/modal.css";
import "./Assets/styles/partials/error.css";
import "./Assets/styles/partials/forms.css";

const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
		,
	</React.StrictMode>,
	document.getElementById("root")
);
