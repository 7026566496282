import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { CircularProgress, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { exchangeGoods } from "../../Store/Actions/goodsActions";
import { ScannerContext } from "../../Store/ScanerContext";
import { locationRegister } from "../../Store/Prov_Templates/location_templates";
import { transportGoods } from "../../Store/Actions/goodsActions";
import { transport_goods } from "../../Store/Prov_Templates/transport_goods";

function inside(point, vs) {
	var x = point[0];
	var y = point[1];

	var inside = false;
	for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
		var xi = vs[i][0],
			yi = vs[i][1];
		var xj = vs[j][0],
			yj = vs[j][1];
		// eslint-disable-next-line
		var intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
		if (intersect) inside = !inside;
	}

	return inside;
}

export default function PickUpForm({ closeWindow, productData, setIsLoading, isLoading, emptyProductData }) {
	const { scannedItems, setScannedItems } = useContext(ScannerContext);
	const { userData, token } = useSelector((state) => state.userReducer);
	const { goodsPickedUp, goodsPickedUpError } = useSelector((state) => state.goodsReducer);
	const [newAddressData, setNewAddressData] = useState(null);
	const [error, setError] = useState(null);
	const [coordinates, setCoordinates] = useState(null);
	const [isGettingCoordinates, setIsGettingCoordinates] = useState(false);
	const [address, setAddress] = useState({ street: "", lSerial: "" });

	const history = useHistory();
	const dispatch = useDispatch();

	function handlePickUp(productData, items, locationData, bizCoordinates) {
		const list = [];
		const list2 = [];
		setIsLoading(true);

		productData.forEach((item) => {
			list.push({
				isA: "goods_exchange",
				gserial: item.id.slice(56),
				g_aserial: item.aSerial.slice(56),
				r_aserial: userData.aSerial,
				location: locationData.lSerial,
				geolocation: bizCoordinates,
			});
			list2.push({
				isA: "goods_transport",
				aserial: userData.aSerial,
				quantity: parseInt(item.weight.low),
				unit: item.unit,
				gserial: item.id.slice(56),
				geolocation: bizCoordinates,
				transportType: "Driving",
				goodsType: item.type,
			});
		});
		if (navigator.onLine) {
			dispatch(exchangeGoods(list, token, "collection", list2));
			// setTimeout(() => {
			// 	dispatch(transportGoods(list2, token));
			// }, 3000);
		} else {
			window.localStorage.setItem(`${new Date().getTime()}_pickup`, JSON.stringify(list));
			window.localStorage.setItem(`${new Date().getTime()}_transport`, JSON.stringify(list2));
			setIsLoading(false);
			emptyProductData();
			setScannedItems([]);
			history.push("/inventory");
		}
	}
	function handleChange(el) {
		setAddress({ street: el.target.value.address, lSerial: el.target.value.lSerial });
	}

	useEffect(() => {
		const cancelTokenSource = axios.CancelToken.source();
		//Grab the geolocation of the drop off activity
		setIsGettingCoordinates(true);
		try {
			navigator.geolocation.getCurrentPosition(async (res) => {
				//Get the country of the drop off point
				let coordinates;
				let unknownLocation = {
					street: null,
					city: null,
					country: null,
					region: null,
					postCode: null,
					latitude: null,
					longitude: null,
					aSerial: null,
					lSerial: null,
				};
				console.log("Got your coordinates", res.coords);
				if (res.coords) {
					setCoordinates(`${res.coords.longitude}, ${res.coords.latitude}`);
					coordinates = [res.coords.longitude, res.coords.latitude];

					//Get the geolocation address of the coordinates
					const response = await axios.get(
						`https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates[0]},${coordinates[1]}.json?access_token=pk.eyJ1IjoicHIxZGUiLCJhIjoiY2twZnAwamZkMGszODJ3cmllYTR6MmNtdyJ9.gprPfGqXmOufIdWeV7fK7g`,
						{ cancelToken: cancelTokenSource.token }
					);
					//If the geolocation is working and we get the coordinates
					if (response.status === 200) {
						unknownLocation.latitude = res.coords.latitude.toString();
						unknownLocation.longitude = res.coords.longitude.toString();

						//Fill in the location object.
						response.data.features.forEach((el) => {
							if (el.place_type[0] === "address") unknownLocation.street = `${el.address} ${el.text}`;
							if (el.place_type[0] === "place") unknownLocation.city = el.text;
							if (el.place_type[0] === "district") unknownLocation.region = el.text;
							if (el.place_type[0] === "country") unknownLocation.country = el.text;
							if (el.place_type[0] === "postcode") unknownLocation.postCode = el.text;
						});
						const location = [
							{
								isA: "location_register",
								streetAddress: unknownLocation.street,
								postalCode: unknownLocation.postCode,
								addressLocality: unknownLocation.city,
								addressRegion: unknownLocation.region,
								addressCountry: unknownLocation.country,
								postOfficeBoxNumber: "unknown",
								latitude: unknownLocation.latitude,
								longitude: unknownLocation.longitude,
								elevation: null,
							},
						];
						//Register unknown location and agent
						const unknown_loc = await locationRegister(location, token);
						if (!unknown_loc.error) {
							unknownLocation.lSerial = unknown_loc.lSerial;

							setNewAddressData(unknownLocation);
							setIsGettingCoordinates(false);
						}
						//Fetch list of facilities in the country

						// const response2 = await axios.get(
						// 	`${process.env.REACT_APP_AWS_API_URL}/addresses/fetch_addresses_by_country?country=${unknownLocation.country}&town=${unknownLocation.city}&action=fetch_addresses_by_country`,
						// 	{
						// 		headers: { "x-api-key": process.env.REACT_APP_AWS_API_KEY },
						// 	}
						// );
						// if (response2.status === 200) {
						// 	const list = [];
						// 	response2.data.forEach((item) => {
						// 		list.push(item._fields[0]);
						// 	});
						// 	setNewAddressData(list);
						// 	setIsGettingCoordinates(false);
						// }
						// if (response2.status === 200) {
						// 	let a;
						// 	const addressesData = [];
						// 	response2.data.forEach((record) => {
						// 		addressesData.push(record._fields[0]);
						// 	});

						// 	//Check if coordinates you are in is in the one of the polygons
						// 	addressesData.forEach((f) => {
						// 		if (inside(coordinates, f.polygon)) {
						// 			a = f.lSerial;
						// 		}
						// 	});
						// 	if (a) {
						// 		setNewAddressData(addressesData.find((address) => address.lSerial === a));
						// 		setIsGettingCoordinates(false);
						// 	} else {
						// const location = [
						// 	{
						// 		isA: "location_register",
						// 		streetAddress: unknownLocation.street,
						// 		postalCode: unknownLocation.postCode,
						// 		addressLocality: unknownLocation.city,
						// 		addressRegion: unknownLocation.region,
						// 		addressCountry: unknownLocation.country,
						// 		postOfficeBoxNumber: "unknown",
						// 		latitude: unknownLocation.latitude,
						// 		longitude: unknownLocation.longitude,
						// 		elevation: null,
						// 	},
						// ];
						// //Register unknown location and agent
						// const unknown_loc = await locationRegister(location, token);
						// if (!unknown_loc.error) {
						// 	unknownLocation.lSerial = unknown_loc.lSerial;

						// 	setNewAddressData(unknownLocation);
						// 	setIsGettingCoordinates(false);
						// }
						// 	}
						// } else {
						// 	const location = [
						// 		{
						// 			isA: "location_register",
						// 			streetAddress: unknownLocation.street,
						// 			postalCode: unknownLocation.postCode,
						// 			addressLocality: unknownLocation.city,
						// 			addressRegion: unknownLocation.region,
						// 			addressCountry: unknownLocation.country,
						// 			postOfficeBoxNumber: "unknown",
						// 			latitude: unknownLocation.latitude,
						// 			longitude: unknownLocation.longitude,
						// 			elevation: null,
						// 		},
						// 	];
						// 	//Register unknown location and agent
						// 	const unknown_loc = await locationRegister(location, token);
						// 	if (!unknown_loc.error) {
						// 		unknownLocation.lSerial = unknown_loc.lSerial;
						// 		unknownLocation.aSerial = unknown_loc.aSerial;
						// 		setNewAddressData(unknownLocation);
						// 		setIsGettingCoordinates(false);
						// 	}
						// }
					} else {
						setIsGettingCoordinates(false);
						setCoordinates(null);
						setError(
							<>
								<span className="blue-text">GPS Data Unavailable</span>
								<p>There was a problem retrieveing country data for your location.</p>
								<p>Please see help for a list of supported countries.</p>
							</>
						);
					}
				} else {
					setIsGettingCoordinates(false);
					setCoordinates(null);
					setError(
						<>
							<span className="blue-text">GPS Data Unavailable</span>
							<p>Please enable location or permit access to geolocation on your device.</p>
						</>
					);
				}
			});
		} catch (error) {
			setError(error);
		}
		return () => {
			setIsGettingCoordinates(false);
		};
		// eslint-disable-next-line
	}, [userData]);
	//Handle the successful activity completion
	useEffect(() => {
		if (goodsPickedUp) {
			console.log("Goods are received");
			setIsLoading(false);
			setTimeout(() => {
				emptyProductData();
				setScannedItems([]);
			}, 1500);
		}
		// eslint-disable-next-line
	}, [goodsPickedUp]);
	//Handle the Error if occured
	useEffect(() => {
		if (goodsPickedUpError) {
			setIsLoading(false);
			setError(goodsPickedUpError);
		}
		// eslint-disable-next-line
	}, [goodsPickedUpError]);

	return (
		<div className="modalObscurer">
			<div className="modal">
				{isLoading ? (
					<div className="modalContent">
						<span className="grey-text">
							<CircularProgress
								size={12}
								sx={{
									top: "50%",
									left: "50%",
								}}
							/>
							&nbsp;Uploading data to silubi.io
						</span>
					</div>
				) : (
					goodsPickedUp && (
						<div className="modalContent">
							<span className="blue-text">{goodsPickedUp}</span>
							<p>Goods picked up &amp; added to inventory.</p>
							<p>
								<span className="orange-text">
									<span className="material-icons">error</span>
								</span>
								Goods are now in transit.
							</p>
						</div>
					)
				)}
				{error && (
					<>
						<div className="modalContent">{error.message}</div>
						<div
							className="option-button"
							onClick={() => {
								setError(null);

								closeWindow();
							}}
						>
							<div className="option-button-ring">OK</div>
						</div>
					</>
				)}

				{isGettingCoordinates ? (
					<div className="modalContent">
						<div className="finding-location">
							<span className="material-icons">not_listed_location</span>
						</div>
						<div className="grey-text">Getting position...</div>
						<div
							className="option-button"
							onClick={() => {
								setIsGettingCoordinates(false);
								closeWindow();
							}}
						>
							<div className="option-button-ring">Cancel</div>
						</div>
					</div>
				) : (
					newAddressData &&
					!error &&
					!goodsPickedUp &&
					!isLoading && (
						<div className="modalContent">
							<span className="blue-text">Collecting goods from:</span>
							<p>
								<span className="grey-text">Agent:</span> {productData[0].aSerial.slice(56)}
							</p>
							{/* <Select labelId="demo-simple-select-label" id="demo-simple-select" value={address.street} label="Address:" onChange={handleChange}>
								{newAddressData.map((item) => (
									<MenuItem key={item.lSerial} value={item}>
										{item.address}
									</MenuItem>
								))}
							</Select> */}
							<br />
							<p>
								<span className="grey-text">Address:</span> {newAddressData.city}, {newAddressData.region},{newAddressData.postCode}, {newAddressData.country}
							</p>

							{/* // ----- Remove the button and put loading spinner intead later ----- */}
							<div
								className="option-button"
								onClick={() => {
									handlePickUp(productData, scannedItems, newAddressData, coordinates);
								}}
							>
								<div className="option-button-ring">Submit</div>
							</div>
							<div className="buttonSeparator"></div>
							<div
								className="option-button"
								onClick={() => {
									setError(null);

									setIsGettingCoordinates(false);
									closeWindow();
								}}
							>
								<div className="option-button-ring">Cancel</div>
							</div>
						</div>
					)
				)}
			</div>
		</div>
	);
}
