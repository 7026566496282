export default function AppHeader() {

    return (
        <div className="header">
            <img
              src="https://worldreservetrust.com/wp-content/themes/worldreservetrust/images/silubi_logo_bw.png"
              alt="Silubi&trade;"
              className="silubi-logo"
            />
        </div>
    );
}