import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import jwt_decode from "jwt-decode";
import Cookie from "js-cookie";
import { Route } from "react-router-dom";

function ProtectedRoute({ component: Component, ...restOfProps }) {
	const history = useHistory();
	const token = Cookie.get("token");
	//const refreshToken = Cookie.get("refreshToken");
	let isAuthenticated;
	if (!token) {
		console.log("Token Not Found");
		isAuthenticated = false;
	} else {
		try {
			if (jwt_decode(token).exp < new Date().getTime() / 1000) {
				console.log("Token Expired");
				isAuthenticated = false;
			} else if (!jwt_decode(token).sub) {
				console.log("This token is not recognised");
				isAuthenticated = false;
			} else {
				console.log("Token is OK");
				isAuthenticated = true;
			}
		} catch (error) {
			console.log("Error occured");
			isAuthenticated = false;
		}
	}
	return <Route {...restOfProps} render={(props) => (isAuthenticated ? <Component {...props} /> : history.push("/login"))} />;
}

export default ProtectedRoute;
