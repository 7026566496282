const userReducer = (state = false, action) => {
	switch (action.type) {
		case "USER_DATA_RECEIVED":
			return {
				...state,
				userData: action.result,
				token: action.token,
				// refreshToken: action.keycloak.refreshToken,
				// keycloak: action.keycloak,
			};

		case "USERS_LIST_DATA_RECEIVED":
			return {
				...state,
				userList: action.data,
			};
		case "USER_DATA_ERROR":
			return {
				...state,
				authError: action.err,
				keycloak: action.keycloak,
			};
		default:
			return state;
	}
};

export default userReducer;
