const documentReducer = (
	state = {
		documentsError: null,
		documentsData: null,
	},
	action
) => {
	switch (action.type) {
		case "DOCUMENTS_DATA_RECEIVED":
			return {
				...state,
				documentsData: action.result.data,
			};

		case "DOCUMENTS_ERROR":
			return {
				...state,
				documentsError: action.error,
			};
		default:
			return state;
	}
};

export default documentReducer;
