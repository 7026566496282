// const innitState = {
// 	goodsData: null,
// 	goodsDataError: null,
// 	goodsExchanged: false,
// 	goodsExchangedError: null,
// 	productData: null,
// 	productError: null,
// };

const goodsReducer = (
	state = {
		productData: null,
		productDataError: null,
		goodsDroppedOff: false,
		goodsData: null,
		goodsPickedUp: false,
		goodsPickedUpError: null,
		goodsDroppedOffError: null,
		goodsExchanged: null,
		goodsExchangedError: null,
	},
	action
) => {
	switch (action.type) {
		case "GOODS_DATA_RECEIVED":
			return {
				...state,
				goodsData: action.result,
				goodsDataError: null,
				goodsDroppedOff: null,
				goodsPickedUp: null,
				goodsExchanged: null,
				goodsPickedUpError: null,
				goodsDroppedOffError: null,
				goodsExchangedError: null,
			};
		case "GOODS_DROPPED_OFF":
			return {
				...state,
				goodsDroppedOff: true,
				goodsDroppedOffError: null,
			};
		case "GOODS_PICKED_UP":
			return {
				...state,
				goodsPickedUp: true,
				goodsPickedUpError: null,
			};
		case "GOODS_EXCHANGED":
			return {
				...state,
				goodsExchanged: true,
				goodsExchangedError: null,
			};
		case "GOODS_PICKED_UP_ERROR":
			return {
				...state,
				goodsPickedUpError: action.err,
			};
		case "GOODS_DROPPED_OFF_ERROR":
			return {
				...state,
				goodsDroppedOffError: action.err,
			};
		case "GOODS_EXCHANGED_ERROR":
			return {
				...state,
				goodsExchangedError: action.err,
			};
		case "GOODS_TRANSPORT_ERROR":
			return {
				...state,
				goodsTransportedError: action.error,
			};
		case "GOODS_ERROR":
			return {
				goodsDataError: action.error,
			};
		case "PRODUCT_DATA_RECEIVED":
			return {
				...state,
				productData: action.result,
			};
		case "PRODUCT_DATA_ERROR":
			return {
				...state,
				productDataError: action.error,
			};

		default:
			return state;
	}
};

export default goodsReducer;
