import { useContext } from "react";
import { ScannerContext } from "../../Store/ScanerContext";

export default function ScannerButton() {
	const { setIsScanning } = useContext(ScannerContext);

	return (
		<div
			className="scannerIcon"
			onClick={() => {
				setIsScanning(true);
			}}
		>
			<span className="material-icons">qr_code_scanner</span>
		</div>
	);
}
