import { Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { queryParams } from "../Store/GetSearch";

import Cookie from "js-cookie";
export default function LandingPage() {
	const { search } = useLocation();
	if (Cookie.get("token")) {
		return <Redirect to={`/inventory`} />;
	} else {
		return <Redirect to={`/trace${queryParams(search, "urlQuery") ? "?gSerial=" + queryParams(search, "urlQuery") : ""}`} />;
	}
}
