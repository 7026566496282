import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { exchangeGoods } from "../../Store/Actions/goodsActions";
import { ScannerContext } from "../../Store/ScanerContext";
import { CircularProgress } from "@mui/material";
import { locationRegister } from "../../Store/Prov_Templates/location_templates";
import { agentRegister } from "../../Store/Prov_Templates/user_templates";
import { transport_goods } from "../../Store/Prov_Templates/transport_goods";
function inside(point, vs) {
	var x = point[0];
	var y = point[1];

	var inside = false;
	for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
		var xi = vs[i][0],
			yi = vs[i][1];
		var xj = vs[j][0],
			yj = vs[j][1];
		// eslint-disable-next-line
		var intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
		if (intersect) inside = !inside;
	}

	return inside;
}

export default function ExchangeForm({ closeWindow, productData, setIsLoading, isLoading, emptyProductData }) {
	const { scannedItems, setScannedItems } = useContext(ScannerContext);
	const { userData, token } = useSelector((state) => state.userReducer);
	const { goodsExchanged, goodsExchangedError } = useSelector((state) => state.goodsReducer);
	const [newAddressData, setNewAddressData] = useState(null);
	const [coordiantes, setCoordinates] = useState(null);
	const [isGettingCoordinates, setIsGettingCoordinates] = useState(false);
	const [error, setError] = useState(null);

	const dispatch = useDispatch();
	const history = useHistory();

	function handleExchange(productData, items, locationData, bizCoordinates) {
		const exchangeList = [];
		const transportList = [];
		setIsLoading(true);
		productData.forEach((item) => {
			if (item.action === "dropOff") {
				exchangeList.push({
					isA: "goods_exchange",
					gserial: item.id.slice(56),
					g_aserial: userData.aSerial,
					r_aserial: item.aSerial.slice(56),
					location: locationData.lSerial,
					geolocation: bizCoordinates,
				});
			} else {
				exchangeList.push({
					isA: "goods_exchange",
					gserial: item.id.slice(56),
					g_aserial: item.aSerial.slice(56),
					r_aserial: userData.aSerial,
					location: locationData.lSerial,
					geolocation: bizCoordinates,
				});
				transportList.push({
					isA: "goods_transport",
					aserial: userData.aSerial,
					quantity: parseInt(item.weight.low),
					unit: item.unit,
					gserial: item.id.slice(56),
					geolocation: bizCoordinates,
					transportType: "Driving",
					goodsType: productData[0].type,
				});
			}
		});
		if (navigator.onLine) {
			dispatch(exchangeGoods(exchangeList, token, "exchanging"));
			setTimeout(() => {
				dispatch(transport_goods(transportList, token));
			}, 3000);
		} else {
			window.localStorage.setItem(`${new Date().getTime()}_exchange}`, JSON.stringify(exchangeList));
			window.localStorage.setItem(`${new Date().getTime()}_transport}`, JSON.stringify(transportList));
			setIsLoading(false);
			emptyProductData();
			setScannedItems([]);
			history.state("/inventory");
		}
	}

	useEffect(() => {
		const cancelTokenSource = axios.CancelToken.source();
		//Grab the geolocation of the drop off activity
		setIsGettingCoordinates(true);
		try {
			navigator.geolocation.getCurrentPosition(async (res) => {
				//Get the country of the drop off point
				let coordinates;
				let unknownLocation = {
					street: null,
					city: null,
					country: null,
					region: null,
					postCode: null,
					latitude: null,
					longitude: null,
					aSerial: null,
					lSerial: null,
				};
				if (res.coords) {
					setCoordinates(`${res.coords.longitude}, ${res.coords.latitude}`);
					coordinates = [res.coords.longitude, res.coords.latitude];

					//Get the geolocation address of the coordinates
					const response = await axios.get(
						`https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates[0]},${coordinates[1]}.json?access_token=pk.eyJ1IjoicHIxZGUiLCJhIjoiY2twZnAwamZkMGszODJ3cmllYTR6MmNtdyJ9.gprPfGqXmOufIdWeV7fK7g`,
						{ cancelToken: cancelTokenSource.token }
					);
					//If the geolocation is working and we get the coordinates
					if (response.status === 200) {
						unknownLocation.latitude = res.coords.latitude.toString();
						unknownLocation.longitude = res.coords.longitude.toString();

						//Fill in the location object.
						response.data.features.forEach((el) => {
							if (el.place_type[0] === "address") unknownLocation.street = `${el.address} ${el.text}`;
							if (el.place_type[0] === "place") unknownLocation.city = el.text;
							if (el.place_type[0] === "district") unknownLocation.region = el.text;
							if (el.place_type[0] === "country") unknownLocation.country = el.text;
							if (el.place_type[0] === "postcode") unknownLocation.postCode = el.text;
						});

						//Fetch list of facilities in the country
						const response2 = await axios.get(
							`${process.env.REACT_APP_AWS_API_URL}/addresses/fetch_addresses_by_country?country=${unknownLocation.country}&action=fetch_addresses_by_country`,
							{ cancelToken: cancelTokenSource.token }
						);

						if (response2.status === 200) {
							let a;
							const addressesData = [];
							response2.data.forEach((record) => {
								addressesData.push(record._fields[0]);
							});

							//Check if coordinates you are in is in the one of the polygons
							addressesData.forEach((f) => {
								if (inside(coordinates, f.polygon)) {
									a = f.lSerial;
								}
							});
							if (a) {
								setNewAddressData(addressesData.find((address) => address.lSerial === a));
								setIsGettingCoordinates(false);
							} else {
								const location = [
									{
										isA: "location_register",
										streetAddress: unknownLocation.street,
										postalCode: unknownLocation.postCode,
										addressLocality: unknownLocation.city,
										addressRegion: unknownLocation.region,
										addressCountry: unknownLocation.country,
										postOfficeBoxNumber: "unknown",
										latitude: unknownLocation.latitude,
										longitude: unknownLocation.longitude,
										elevation: null,
									},
								];
								//Register unknown location and agent
								const unknown_loc = await locationRegister(location, token);
								if (!unknown_loc.error) unknownLocation.lSerial = unknown_loc.lSerial;
								const agent = [
									{
										isA: "agent_register",
										name: `unknown_agent_${new Date().getTime()}`,
										address: unknown_loc.lSerial,
										geolocation: `${res.coords.longitude}, ${res.coords.latitude}`,
										type: "unknown",
										date: new Date(),
										sproperties: JSON.stringify({ kcID: "unknown", email: "unknown", username: "unknown" }).replaceAll('"', "#"),
									},
								];
								const unknown_agent = await agentRegister(agent, token);
								if (!unknown_agent.error) unknownLocation.aSerial = unknown_agent.aSerial;
								setNewAddressData(unknownLocation);
								setIsGettingCoordinates(false);
							}
						} else {
							const agent = {
								name: "unknown",
								address: null,
								geolocation: `${res.coords.longitude}, ${res.coords.latitude}`,
								type: "unknown",
								date: new Date(),
								sproperties: JSON.stringify({ kcID: "unknown", email: "unknown", username: "unknown" }).replaceAll('"', "#"),
							};
							const location = [
								{
									isA: "location_register",
									streetAddress: unknownLocation.street,
									postalCode: unknownLocation.postCode,
									addressLocality: unknownLocation.city,
									addressRegion: unknownLocation.region,
									addressCountry: unknownLocation.country,
									postOfficeBoxNumber: "unknown",
									latitude: unknownLocation.latitude,
									longitude: unknownLocation.longitude,
									elevation: null,
								},
							];
							//Register unknown location and agent
							const unknown_loc = await locationRegister(location, token, agent);
							if (!unknown_loc.error) {
								unknownLocation.lSerial = unknown_loc.lSerial;
								unknownLocation.aSerial = unknown_loc.aSerial;
								setNewAddressData(unknownLocation);
								setIsGettingCoordinates(false);
							}
						}
					} else {
						setIsGettingCoordinates(false);
						setCoordinates(null);
						setError(
							<>
								<span className="blue-text">GPS Data Unavailable</span>
								<p>There was a problem retrieveing country data for your location.</p>
								<p>Please see help for a list of supported countries.</p>
							</>
						);
					}
				} else {
					setIsGettingCoordinates(false);
					setCoordinates(null);
					setError(
						<>
							<span className="blue-text">GPS Data Unavailable</span>
							<p>Please enable location or permit access to geolocation on your device.</p>
						</>
					);
				}
			});
		} catch (error) {
			setIsGettingCoordinates(false);
			setCoordinates(null);
			setError(error);
		}
		return () => {
			setIsGettingCoordinates(false);
			cancelTokenSource.cancel();
		};
		// eslint-disable-next-line
	}, [userData]);
	//Handle the successful activity completion
	useEffect(() => {
		if (goodsExchanged) {
			setIsLoading(false);
			setTimeout(() => {
				emptyProductData();
				setScannedItems([]);
			}, 1500);
		}
		// eslint-disable-next-line
	}, [goodsExchanged]);
	//Handle the Error if occured
	useEffect(() => {
		if (goodsExchangedError) {
			setIsLoading(false);
			setError(goodsExchangedError);
		}
		// eslint-disable-next-line
	}, [goodsExchangedError]);
	return (
		<div className="modalObscurer">
			<div className="modal">
				{isLoading ? (
					<div className="modalContent">
						<span className="grey-text">
							<CircularProgress
								size={12}
								sx={{
									top: "50%",
									left: "50%",
								}}
							/>
							&nbsp;Uploading data to silubi.io
						</span>
					</div>
				) : (
					goodsExchanged && (
						<div className="modalContent">
							<span className="blue-text">{goodsExchanged}</span>
							<p>Goods exchanged &amp; and your inventory is updated.</p>
						</div>
					)
				)}
				{error && (
					<>
						<div className="modalContent">{error.message}</div>
						<div
							className="option-button"
							onClick={() => {
								setError(null);

								closeWindow();
							}}
						>
							<div className="option-button-ring">OK</div>
						</div>
					</>
				)}

				{isGettingCoordinates ? (
					<div className="modalContent">
						<div className="finding-location">
							<span className="material-icons">not_listed_location</span>
						</div>
						<div className="grey-text">Getting position...</div>
						<div
							className="option-button"
							onClick={() => {
								setIsGettingCoordinates(false);
								closeWindow();
							}}
						>
							<div className="option-button-ring">Cancel</div>
						</div>
					</div>
				) : (
					newAddressData &&
					!error &&
					!goodsExchanged &&
					!isLoading && (
						<div className="modalContent">
							<span className="blue-text">Exchanges goods with:</span>
							<p>
								<span className="grey-text">Name:</span> {newAddressData.aSerial}
							</p>
							<p>
								<span className="grey-text">Address:</span> {newAddressData.city}, {newAddressData.region},{newAddressData.postCode}, {newAddressData.country}
							</p>

							{/* //Remove the button and put loading spinner intead latter */}
							<div
								className="option-button"
								onClick={() => {
									handleExchange(productData, scannedItems, newAddressData, coordiantes);
								}}
							>
								<div className="option-button-ring">Submit</div>
							</div>
							<div className="buttonSeparator"></div>
							<div
								className="option-button"
								onClick={() => {
									setError(null);
									closeWindow();
									setIsLoading(false);
								}}
							>
								<div className="option-button-ring">Cancel</div>
							</div>
						</div>
					)
				)}
			</div>
		</div>
	);
}
