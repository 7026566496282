import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import TracePage from "./Pages/TracePage";
import InventoryPage from "./Pages/InventoryPage";
import ScanResultPage from "./Pages/ScanResultPage";
import LandingPage from "./Pages/LandingPage";
import Login from "./Pages/Login";
import ProtectedRoute from "./Store/ProtectedRoute";
import MenuPanel from "./Components/UI/menuPanel";
import ScannerButton from "./Components/UI/scannerButton";
import { fetchUser } from "./Store/Actions/userActions";

import { exchangeGoods } from "./Store/Actions/goodsActions";
import { ScannerContext } from "./Store/ScanerContext";
import jwt_decode from "jwt-decode";
import Cookie from "js-cookie";
import "./Assets/styles/App.css";

function App() {
	const dispatch = useDispatch();

	const { userData } = useSelector((state) => state.userReducer);
	const [isScanning, setIsScanning] = useState(false);

	const [scannedItems, setScannedItems] = useState([]);
	const [camError, setCamError] = useState(false);
	const token = Cookie.get("token");
	useEffect(() => {
		if (!userData && token && navigator.onLine) {
			const { sub } = jwt_decode(token);

			dispatch(fetchUser(sub, token));
		}
		// eslint-disable-next-line
	}, [token]);
	useEffect(() => {
		if (navigator.onLine && userData) {
			const f = Object.keys(localStorage);

			if (f.length !== 0) {
				f.sort().forEach((t) => {
					if (t.includes("dropoff")) {
						dispatch(exchangeGoods(JSON.parse(localStorage.getItem(t)), token, "dropoff"));
						localStorage.removeItem(t);
					}
					if (t.includes("pickup")) {
						dispatch(exchangeGoods(JSON.parse(localStorage.getItem(t)), token, "collection"));
						localStorage.removeItem(t);
					}
					if (t.includes("exchange")) {
						dispatch(exchangeGoods(JSON.parse(localStorage.getItem(t)), token, "exchanging"));
						localStorage.removeItem(t);
					}
					setTimeout(() => {
						if (t.includes("transport")) {
							dispatch(exchangeGoods(JSON.parse(localStorage.getItem(t)), token));
							localStorage.removeItem(t);
						}
					}, 3000);
				});
			}
		}
	}, [userData]);
	useEffect(() => {
		navigator.geolocation.getCurrentPosition(async (res) => {
			const coordinates = await `${res.coords.longitude}, ${res.coords.latitude}`;
			console.log("Got new coordiantes :: " + coordinates);
			const oldCoordinates = localStorage.getItem("coordinates");
			if (coordinates !== oldCoordinates) localStorage.setItem("coordinates", coordinates);
		});
	}, []);

	return (
		<div className="App">
			<>
				<ScannerContext.Provider value={{ isScanning, setIsScanning, scannedItems, setScannedItems, camError, setCamError }}>
					<ScannerButton />
					<MenuPanel />

					<Router>
						<Switch>
							<Route path="/login" component={Login} />
							<Route exact path="/" component={LandingPage} />
							<Route path="/trace" component={TracePage} />

							<ProtectedRoute path="/inventory" component={InventoryPage} />

							<ProtectedRoute path="/scanresult" component={ScanResultPage} />
						</Switch>
					</Router>
				</ScannerContext.Provider>
			</>
		</div>
	);
}
export default App;
