import { useEffect, useState } from "react";
import { exchangeGoods } from "../../Store/Actions/goodsActions";
import { useDispatch, useSelector } from "react-redux";

import { CircularProgress } from "@mui/material";
import Cookies from "js-cookie";

export default function PayModal({ closeFunction, productData, query, geolocation }) {
	const { userData } = useSelector((state) => state.userReducer);
	const { goodsExchanged, goodsExchangedError } = useSelector((state) => state.goodsReducer);

	const [purchaseProcessing, setPurchaseProcessing] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (goodsExchanged || goodsExchangedError) setPurchaseProcessing(false);
	}, [goodsExchanged, goodsExchangedError]);

	return (
		<div className="modalObscurer">
			<div className="modal">
				{userData ? (
				<>
					<div className="modalItemInfo">
						<div className="modalItemInfo-title">
							{`${productData.weight.low}${productData.unit} ${productData.package} ${productData.title}`}
						</div>
						<div className="modalItemInfo-id">{`${productData.id}`}</div>
						<div className="modalItemInfo-price">&#7785;5.65</div>
					</div>

					{purchaseProcessing ? (
						<div className="modalContent">
							<div className="modalContent-section">
								<div className="modalContent-info">
									<CircularProgress /> Processing your Purchase...
								</div>
							</div>
						</div>
					) : (
						<>
							{goodsExchangedError ? (
								<div className="modalContent">
									<div className="modalContent-section">
										<div className="modalContent-title">Transaction Failed</div>
										<div className="modalContent-info">{goodsExchangedError.message}</div>
									</div>
									<div
										className="option-button"
										onClick={() => {
											dispatch(
												exchangeGoods(
													{
														isA: "goods_exchange",
														gserial: productData.goodsSerial.slice(56),
														goodsType: productData.goodsType,
														quantity: parseInt(productData.goodsWeight),
														unit: productData.goodsUnit,
														g_aserial: productData.aSerial,
														r_aserial: userData.aSerial,
														location: productData.locationID,
														geolocation: geolocation,
													},
													Cookies.get("token"),
													{
														userID: Cookies.get("userID"),
														aSerial: Cookies.get("aSerial"),
													},
													"collection"
												)
											);
											setPurchaseProcessing(true);
										}}
									>
										<div className="option-button-ring">Try Again</div>
									</div>
									<div className="buttonSeparator"></div>
									<div className="cancel-button" onClick={closeFunction}>
										<div className="cancel-button-ring">Cancel</div>
									</div>
								</div>
							) : goodsExchanged ? (
								<div className="modalContent">
									<div className="modalContent-section">
										<div className="modalContent-title">Success!</div>
										<div className="modalContent-info">Item has been added to your inventory.</div>
									</div>
									<div
										className="option-button"
										onClick={() => {
											window.location.href = `/inventory`;
										}}
									>
										<div className="option-button-ring">Continue</div>
									</div>
								</div>
							) : (
								<div className="modalContent">
									<div className="modalContent-section">
										<div className="modalContent-title">Seller: {productData.name}</div>
										<div className="modalContent-ID">ID: {`${productData.aSerial}`}</div>
									</div>
									<div className="modalContent-section">
										<div className="modalContent-title">Pay with Silubi&trade;</div>
										<div className="modalContent-info">
											Wallet Ballance: &#7785;200
											<span className="material-icons">arrow_right_alt</span>
											&#7785;194.35
										</div>
									</div>

									{geolocation ? (
										<div
											className="option-button"
											onClick={() => {
												dispatch(
													exchangeGoods(
														{
															isA: "goods_exchange",
															gserial: productData.goodsSerial.slice(56),
															goodsType: productData.goodsType,
															quantity: parseInt(productData.goodsWeight),
															unit: productData.goodsUnit,
															g_aserial: productData.aSerial,
															r_aserial: userData.aSerial,
															location: productData.locationID,
															//location: action === "dropOff" ? receiver.locationID : sender.locationID,
															geolocation: geolocation,
														},
														Cookies.get("token"),
														{
															userID: Cookies.get("userID"),
															aSerial: Cookies.get("aSerial"),
														},
														"collection"
													)
												);
												setPurchaseProcessing(true);
											}}
											variant="contained"
										>
											<div className="option-button-ring">Confirm</div>
										</div>
									) : (
										<div className="modalContent-ID">
											<CircularProgress /> Waiting for GPS...
										</div>
									)}

									<div className="buttonSeparator"></div>
									<div className="cancel-button" onClick={closeFunction}>
										<div className="cancel-button-ring">Cancel</div>
									</div>
								</div>
							)}
					</>
					)}
					</>
				):(
					<div className="modalContent">
						<p className="blue-text">No Wallet Found</p>
						<p>Please login to make purchases.</p>
						<div className="cancel-button" onClick={closeFunction}>
							<div className="cancel-button-ring">Close</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
