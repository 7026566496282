const settingsReducer = (state = false, action) => {
	switch (action.type) {
		case "URL_QUERY_SET":
			return {
				...state,
				urlQuery: action.query,
			};

		default:
			return state;
	}
};

export default settingsReducer;
