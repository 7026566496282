import axios from "axios";
export async function transport_goods(goodsData, token) {
	let serial = [];
	// let list = [];
	let uri = "https://dev2.silubi.io/prov-wrt/provapi/enactor/session";
	//Open session
	try {
		const resp = await axios.post(
			uri,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "text/csv",
				},
			}
		);

		if (resp.status === 200) {
			console.log("Session opened :: " + resp.data.uuid);
			//Post data to the session
			console.log("Posting goods transportation data :: " + JSON.stringify(goodsData, null, 2));
			const resp2 = await axios.post(`${uri}/${resp.data.uuid}`, JSON.stringify(goodsData), {
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/vnd.wrt.prov-enact+json",
					Accept: "text/csv",
				},
			});
			if (resp2.status === 200) {
				console.log("Data posted to session :: " + resp.data.uuid);

				//Sort the repsonse and send back the serial numbers of posted goods

				serial.push(resp2.data.split(",")[1]);
			} else throw new Error(resp2);
		} else throw new Error(resp);
		//Close session
		console.log("Closing session");
		const deleteingSession = await axios.delete(
			`${uri}/${resp.data.uuid}`,

			{
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		// console.log(deleteingSession);
		if (deleteingSession.status === 400) throw new Error(deleteingSession);
		console.log("Session :: " + resp.data.uuid + " closed.");
	} catch (err) {
		serial = { error: err.message };
	}
	return serial;
}
