import QRCode from "react-qr-code";

export default function QRModal({ url, closeFunction }) {
	return (
		<div className="modalObscurer">
			<div className="modal">
				<div className="modalCloser">
					<span className="material-icons-round" onClick={closeFunction}>
						disabled_by_default
					</span>
				</div>
				<div className="modalContent">
					<div className="qrLogo">
						<img
							src="https://worldreservetrust.com/wp-content/themes/worldreservetrust/images/silubi_logo_rgb.png"
							alt="Silubi&trade;"
							className="silubi-QRlogo"
						/>
					</div>
					<div className="qrCode">
						<QRCode size={256} value={url} />
					</div>
					<div className="qrValue">{url}</div>
				</div>
			</div>
		</div>
	);
}
