import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Keycloak from "keycloak-js";

import jwt_decode from "jwt-decode";
import Cookie from "js-cookie";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";

import { fetchUser } from "../Store/Actions/userActions";

export default function Login() {
	const history = useHistory();
	const { userData } = useSelector((state) => state.userReducer);
	const dispatch = useDispatch();

	const initOptions = {
		url: process.env.REACT_APP_KEYCLOAK_CONFIG_URL,
		realm: process.env.REACT_APP_KEYCLOAK_CONFIG_REALM,
		clientId: process.env.REACT_APP_KEYCLOAK_CONFIG_CLIENT_ID,
		onLoad: process.env.REACT_APP_KEYCLOAK_CONFIG_ON_LOAD,
	};
	const token = Cookie.get("token");
	const keycloak = Keycloak(initOptions);
	useEffect(() => {
		//If token is there check if it is not expired
		if (token) {
			console.log("Token found");
			const { exp } = jwt_decode(token);
			if (exp < new Date().getTime() / 1000) {
				console.log("Token expired");
				Cookie.remove("token");

				keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
					//if (!auth) window.location.reload();
					keycloak
						.loadUserInfo()
						.then((userInfo) => {
							Cookie.set("token", keycloak.token, {
								secure: true,
								SameSite: "None",
							});

							dispatch(fetchUser(userInfo.sub, keycloak.token));
						})
						.catch((error) => {
							console.log("Authentication Failed", error);
						});
				});
			}
		} else {
			console.log("Token not found");
			keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
				//if (!auth) window.location.reload();
				keycloak
					.loadUserInfo()
					.then((userInfo) => {
						Cookie.set("token", keycloak.token, {
							secure: true,
							SameSite: "None",
						});

						dispatch(fetchUser(userInfo.sub, keycloak.token));
					})
					.catch((error) => {
						console.log("Authentication Failed", error);
					});
			});
		}
		// eslint-disable-next-line
	}, [token]);
	useEffect(() => {
		if (userData) {
			setTimeout(() => {
				history.push("/inventory");
			}, 3000);
		}
		// eslint-disable-next-line
	}, [userData]);

	if (userData)
		return (
			<div className="App-body">
				<div className="modalObscurer">
					<div className="modal">
						<div className="modalContent">
							<span className="blue-text">Welcome {userData.username}.</span>
							<br /> You will be redirected to your Inventory.
						</div>
					</div>
				</div>
			</div>
		);
	else
		return (
			<div className="App-body">
				<div className="modalObscurer">
					<div className="modal">
						<div className="modalContent">
							<CircularProgress
								size={12}
								sx={{
									top: "50%",
									left: "50%",
								}}
							/>
							&nbsp;Loading user profile...
						</div>
					</div>
				</div>
			</div>
		);
}
