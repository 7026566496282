import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";

import "./mapbox-gl.css";
function addMarker(name, coordinates, map, popup, width, height) {
	const el = document.createElement("div");
	el.className = name;
	el.style.width = `${width}px`;
	el.style.height = `${height}px`;

	// make a marker for each feature and add to the map
	const marker = new mapboxgl.Marker(el, {
		offset: {
			x: -width / 2,
			y: -height,
		},
		anchor: "top-left",
	})
		.setLngLat(coordinates)

		.setPopup(
			new mapboxgl.Popup({
				anchor: "bottom", // To show popup on top
				offset: { bottom: [0, -30] }, // To prevent popup from over shadowing the marker.
				closeOnClick: false,
			}) // add popups
				.setHTML(popup)
		)

		.addTo(map);

	map.flyTo({
		center: coordinates,
		zoom: map.getZoom(),
		essential: true,
	});
	return marker;
}
function create_marker(color, marker, clicked, list, x, y, map) {
	marker.marker.remove();
	const index = list.indexOf(marker);
	list.splice(index, 1);

	let new_marker = addMarker(color, [marker.marker._lngLat.lng, marker.marker._lngLat.lat], map, marker.popup, x, y);

	list.push({ id: marker.id, marker: new_marker, clicked: clicked, popup: marker.popup });

	return list;
}
export default React.memo(function Map({ mapData, clicked }) {
	const mapContainer = useRef(null);
	const [mapError, setMapError] = useState(null);
	const map = useRef(null);

	const [markers, setNewMarkers] = useState([]);

	function setMarkers(clickedMarker = null) {
		let markersList = markers;
		// find a clicked marker
		let previously_selected_marker = markersList.find((item) => item.clicked === true);
		if (previously_selected_marker && previously_selected_marker.id === clickedMarker.bizActivityID) {
			create_marker("commission_marker", previously_selected_marker, false, markersList, 40, 40, map.current);
			return;
		}
		//Re-create the marker
		if (previously_selected_marker) create_marker("commission_marker", previously_selected_marker, false, markersList, 40, 40, map.current);

		let selected_marker = markersList.find((item) => item.id === clickedMarker.bizActivityID);

		create_marker("clicked_marker", selected_marker, true, markersList, clickedMarker.bizActivity, 60, 40, map.current);
	}

	// function setLines(clickedLine = null) {
	// 	Transportation.data.features.forEach((el) => {
	// 		if (clicked.uri === el.properties.agent.properties.uri) {
	// 			el.properties.color = "#FF0000";
	// 			if (map.current.getLayer("lines-clicked")) {
	// 				map.current.removeLayer("lines-clicked");
	// 				map.current.removeSource("lines-clicked");
	// 			}
	// 			console.log(el);
	// 			map.current.addSource("lines-clicked", {
	// 				type: "geojson",
	// 				data: { type: "FeatureCollection", features: [el] },
	// 			});

	// 			map.current.addLayer({
	// 				id: "lines-clicked",
	// 				type: "line",
	// 				source: "lines-clicked",
	// 				layout: {
	// 					"line-join": "round",
	// 					"line-cap": "round",
	// 				},
	// 				paint: {
	// 					"line-color": ["get", "color"],
	// 					"line-width": 5,
	// 				},
	// 			});
	// 			console.log(map.current.getLayer("lines-clicked"));
	// 		} else {
	// 			if (map.current.getLayer("lines-clicked")) {
	// 				map.current.removeLayer("lines-clicked");
	// 				map.current.removeSource("lines-clicked");
	// 			}
	// 		}
	// 	});
	// }
	const size = 100;
	const pulsingDot = {
		width: size,
		height: size,
		data: new Uint8Array(size * size * 4),

		// When the layer is added to the map,
		// get the rendering context for the map canvas.
		onAdd: function () {
			const canvas = document.createElement("canvas");
			canvas.width = this.width;
			canvas.height = this.height;
			this.context = canvas.getContext("2d");
		},

		// Call once before every frame where the icon will be used.
		render: function () {
			const duration = 1000;
			const t = (performance.now() % duration) / duration;

			const radius = (size / 2) * 0.3;
			const outerRadius = (size / 2) * 0.7 * t + radius;
			const context = this.context;

			// Draw the outer circle.
			context.clearRect(0, 0, this.width, this.height);
			context.beginPath();
			context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
			context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
			context.fill();

			// Draw the inner circle.
			context.beginPath();
			context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
			context.fillStyle = "rgba(255, 100, 100, 1)";
			context.strokeStyle = "white";
			context.lineWidth = 2 + 4 * (1 - t);
			context.fill();
			context.stroke();

			// Update this image's data with data from the canvas.
			this.data = context.getImageData(0, 0, this.width, this.height).data;

			// Continuously repaint the map, resulting
			// in the smooth animation of the dot.
			map.current.triggerRepaint();

			// Return `true` to let the map know that the image was updated.
			return true;
		},
	};

	useEffect(() => {
		if (map.current) {
			if (clicked) setMarkers(clicked);
			return;
		}

		mapboxgl.accessToken = "pk.eyJ1IjoicHIxZGUiLCJhIjoiY2twZnAwamZkMGszODJ3cmllYTR6MmNtdyJ9.gprPfGqXmOufIdWeV7fK7g";

		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: "mapbox://styles/mapbox/light-v10",

			center: [mapData[mapData.length - 1].geometry.coordinates[0], mapData[mapData.length - 1].geometry.coordinates[1]],
			zoom: 7,
		});
		//* *Sort the lines Coordinates to match LngLtd format
		// map.current.on("click", (e) => {
		// 	console.log(e.originalEvent);
		// 	//console.log(e.originalEvent.target, markers[2].marker.getElement());
		// });
		let newLines = [];
		mapData.forEach((el) => {
			if (!el.geometry.coordinates[0]) {
				setMapError("Sorry we are experincing some problems with the map");
				return;
			}
			if (el.properties.bizActivity !== "Commissioned") newLines.push([el.geometry.coordinates[0], el.geometry.coordinates[1]]);
		});

		if (newLines.length > 1) {
			//Post lines to Openroute and get back constructed route

			axios
				.post(
					`https://api.openrouteservice.org/v2/directions/driving-car/geojson`,
					{ coordinates: newLines },
					{
						headers: {
							"Content-Type": "application/json; charset=utf-8",
							Authorization: "5b3ce3597851110001cf62481ecca82985b54149bccc2f976e7d8699",
						},
					}
				)
				.then((resp) => {
					map.current.on("load", function () {
						map.current.addSource("lines", {
							type: "geojson",
							data: resp.data.features[0],
						});

						//Add pulsating dot and the end of the route

						if (mapData[mapData.length - 1].properties.bizActivity === "In Transit") {
							map.current.addImage("pulsing-dot", pulsingDot, { pixelRatio: 2 });

							map.current.addSource("dot-point", {
								type: "geojson",
								data: {
									type: "FeatureCollection",
									features: [
										{
											type: "Feature",
											geometry: {
												type: "Point",
												coordinates: mapData[mapData.length - 1].geometry.coordinates,
												// coordinates: resp.data.features[0].geometry.coordinates[resp.data.features[0].geometry.coordinates.length - 1], // icon position [lng, lat]
											},
										},
									],
								},
							});

							map.current.addLayer({
								id: "layer-with-pulsing-dot",
								type: "symbol",
								source: "dot-point",
								layout: {
									"icon-image": "pulsing-dot",
								},
							});
							const g = [];

							mapData.forEach((el, i) => {
								if (el.properties.bizActivity === "In Transit" && i !== mapData.length - 1) {
									g.push(el);
								}
							});
							map.current.addSource("f-point", {
								type: "geojson",
								data: {
									type: "FeatureCollection",
									features: g,
								},
							});

							map.current.addLayer({
								id: "f-point",
								source: "f-point",
								type: "circle",
								paint: {
									"circle-radius": 5,
									"circle-color": "#007cbf",
								},
							});
						} else {
							const g = [];
							mapData.forEach((el) => {
								if (el.properties.bizActivity === "In Transit") {
									g.push(el);
								}
							});
							map.current.addSource("f-point", {
								type: "geojson",
								data: {
									type: "FeatureCollection",
									features: g,
								},
							});

							map.current.addLayer({
								id: "f-point",
								source: "f-point",
								type: "circle",
								paint: {
									"circle-radius": 5,
									"circle-color": "#007cbf",
								},
							});
						}

						map.current.addLayer({
							id: "lines-viz",
							type: "line",
							source: "lines",
							layout: {
								"line-join": "round",
								"line-cap": "round",
							},
							paint: {
								"line-color": "#93b5b3",
								"line-width": 3,
							},
						});
					});
				})
				.catch((e) => {
					setMapError(e.message);
					console.log(e);
				});
		}

		//Set markers on the Map
		mapData.forEach((marker) => {
			if (marker.properties.bizActivity === "Exchanged") {
				const popup = `<h3>${marker.properties.goodWeight.low}${marker.properties.goodUnit} ${marker.properties.goodType} was exchanged between ${marker.properties.bizAgent} and ${marker.properties.bizAgent2}</h3>`;
				const newMarker = addMarker("commission_marker", [marker.geometry.coordinates[0], marker.geometry.coordinates[1]], map.current, popup, 40, 40);
				setNewMarkers((markers) =>
					markers.concat({
						id: marker.properties.bizActivityID,
						name: marker.properties.bizAgent,
						popup: popup,
						marker: newMarker,
						clicked: false,
						type: "marker",
					})
				);
			}
			if (marker.properties.bizActivity === "Commissioned") {
				const popup = `<h3>${marker.properties.goodWeight.low}${marker.properties.goodUnit} ${marker.properties.goodType} was comissioned by ${marker.properties.bizAgent}</h3>`;

				const newMarker = addMarker("commission_marker", [marker.geometry.coordinates[0], marker.geometry.coordinates[1]], map.current, popup, 40, 40);
				setNewMarkers((markers) =>
					markers.concat({
						id: marker.properties.bizActivityID,
						name: marker.properties.bizAgent,
						marker: newMarker,
						popup: popup,
						clicked: false,
						type: "marker",
					})
				);
			}
		});
		//Make sure the map fits all the markers
		const bounds = [];
		const h = [];
		mapData.forEach((it) => {
			h.push([it.geometry.coordinates[0], it.geometry.coordinates[1]]);
		});

		h.sort((a, b) => {
			return a[1] - b[1];
		}).forEach((v, i) => {
			if (i === 0) bounds.push(v[0] - 0.1, v[1] - 0.1);

			if (i === h.length - 1) bounds.push(v[0] + 0.4, v[1] + 0.4);
		});
		//Set the bounds of the markers to the map
		map.current.fitBounds(bounds);

		// return () => {
		// 	map.current.remove();
		// };
		// eslint-disable-next-line
	}, [clicked, mapData]);
	if (mapError) {
		return <div>{mapError}</div>;
	} else return <div ref={mapContainer} />;
});
