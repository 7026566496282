import goodsReducer from "./goodsReducer";
import userReducer from "./userReducer";
import addressReducer from "./addressReducer";
import settingsReducer from "./settingsReducer";
import documentReducer from "./documentReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
	settingsReducer,
	addressReducer,
	userReducer,
	goodsReducer,
	documentReducer,
});

export default rootReducer;
