const addressReducer = (state = false, action) => {
	switch (action.type) {
		case "ADDRESSES_LIST_DATA_RECEIVED":
			return {
				...state,
				addressListData: action.data,
			};
		case "ADDRESSES_LIST_DATA_ERROR":
			return {
				...state,
				addressListError: action.error,
			};
		case "DEFAULT_ADDRESS_DATA_RECEIVED":
			return {
				...state,
				defaultAddress: action.data[0],
			};
		case "ADDRESS_DATA_RECEIVED":
			return {
				...state,
				addressData: action.data[0],
			};

		default:
			return state;
	}
};

export default addressReducer;
