import axios from "axios";
export async function locationRegister(locationData, token, agentData = null) {
	let serial = { lSerial: null, error: null };
	let uri = "https://dev2.silubi.io/prov-wrt/provapi/enactor/session";
	try {
		const resp = await axios.post(
			uri,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "text/csv",
				},
			}
		);

		if (resp.status === 200) {
			console.log("Session opened :: " + resp.data.uuid);
			console.log("Posting location data :: " + JSON.stringify(locationData, null, 2));
			const resp2 = await axios.post(`${uri}/${resp.data.uuid}`, JSON.stringify(locationData), {
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/vnd.wrt.prov-enact+json",
					Accept: "text/csv",
				},
			});

			if (resp2.status === 200) {
				serial.lSerial = resp2.data.split(",")[1];

				console.log("Received serial of the location :: " + resp2.data.split(",")[1]);
			} else throw new Error(resp2);
		} else throw new Error(resp);
		//Close session
		console.log("Closing session :: " + resp.data.uuid);
		const deleteingSession = await axios.delete(`${uri}/${resp.data.uuid}`, {
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});

		if (deleteingSession.status === 400) throw new Error(deleteingSession);
		console.log("Session :: " + resp.data.uuid + " closed. " + JSON.stringify(deleteingSession.data, null, 2));
	} catch (err) {
		serial = { error: err.message };
	}

	return serial;
}
