import React, { useState, Fragment } from "react";
import "../../Assets/styles/partials/menu.css";
import { useSelector } from "react-redux";
import Cookie from "js-cookie";

export default function MenuPanel() {
	const { userData } = useSelector((state) => state.userReducer);

	const menuMaskOn = { opacity: "1", zIndex: "99" };
	const menuMaskOff = { opacity: "0", zIndex: "-10" };
	const menuOpen = { right: `-1px` };
	const menuClose = { right: `-100%` };

	const [menuMask, setMenuMask] = useState(menuMaskOff);
	const [menuControl, setMenuControl] = useState(menuClose);

	return (
		<Fragment>
			{userData ? (
				<Fragment>
					{userData.pictureURL ? (
						<div
							style={{ backgroundImage: "url(" + userData.pictureURL + ")" }}
							className="accountImg"
							onClick={() => {
								setMenuControl(menuOpen);
								setMenuMask(menuMaskOn);
							}}
						></div>
					) : (
						<div
							className="accountIcon"
							onClick={() => {
								setMenuControl(menuOpen);
								setMenuMask(menuMaskOn);
							}}
						>
							<span className="material-icons">account_circle</span>
						</div>
					)}
				</Fragment>
			) : (
				<div
					className="accountIcon"
					onClick={() => {
						setMenuControl(menuOpen);
						setMenuMask(menuMaskOn);
					}}
				>
					<span className="material-icons">account_circle</span>
				</div>
			)}

			<div className="menu">
				{userData ? (
					<div className="menuPanel" style={menuControl}>
						<div className="menuHeader">
							<span
								className="menuUsername"
								onClick={() => {
									setMenuControl(menuClose);
									setMenuMask(menuMaskOff);
								}}
							>
								{userData.username}
							</span>
							<span
								className="menuEmail"
								onClick={() => {
									setMenuControl(menuClose);
									setMenuMask(menuMaskOff);
								}}
							>
								{userData.email}
							</span>
							{userData.pictureURL ? (
								<div
									style={{ backgroundImage: "url(" + userData.pictureURL + ")" }}
									className="menuAccountImg"
									onClick={() => {
										setMenuControl(menuClose);
										setMenuMask(menuMaskOff);
									}}
								></div>
							) : (
								<span
									className="material-icons-round"
									onClick={() => {
										setMenuControl(menuClose);
										setMenuMask(menuMaskOff);
									}}
								>
									account_circle
								</span>
							)}
						</div>

						<div className="menuContent">
							<span className="menuLargeText">
								{userData.name ? (
									<span className="menuBoldText">{userData.name}</span>
								) : userData.firstName && userData.secondName ? (
									<span className="menuBoldText">{userData.firstName + " " + userData.secondName}</span>
								) : (
									<span className="menuBoldText">{userData.username}</span>
								)}
							</span>
							<br />
							<span className="menuSmallText">
								<span className="menuBoldText">ID:</span> {userData.aSerial}
							</span>

							<div className="button">
								<div className="wallet-button">
									<div className="ring">
										<span className="button-data">&#7785;200</span>
									</div>
								</div>
								<span className="main-button-title">Silubi&trade; Balance</span>
							</div>

							<div className="menuAccountDetails">
								<span className="menuBoldText">Account Details</span>
								<br />
								Default Currency: {userData.defaultCurrency} {/* <---------------------------------------- No longer works from Neo4j */}
								<br />
								<a
									href="https://id.silubi.io/auth/realms/silubi/protocol/openid-connect/logout?redirect_uri=https://scan.silubi.io"
									className="menuLink"
									onClick={() => Cookie.remove("token")}
								>
									Logout
								</a>
							</div>

							<div className="button">
								<span className="menuBoldText">My Inventory</span>
								<br />
								<a href="/inventory" className="option-button">
									<div className="option-button-ring">View Items</div>
								</a>
							</div>

							<div className="menuSeparator"></div>

							<span className="menuBoldText">Manage your account at</span>
							<br />
							<a href="https://www.silubi.com" target="new" className="menuLink">
								silubi.io
							</a>
						</div>
						<div className="menuFooter">v1.2</div>
					</div>
				) : (
					<div className="menuPanel" style={menuControl}>
						<div className="menuHeader">
							<span
								className="material-icons-round"
								onClick={() => {
									setMenuControl(menuClose);
									setMenuMask(menuMaskOff);
								}}
							>
								account_circle
							</span>
						</div>
						<div className="menuContent">
							<span className="menuBoldText">You are not logged in.</span>

							<div className="button">
								<a href="/login" className="option-button">
									<div className="option-button-ring">Login / Register</div>
								</a>
								<br />
								at&nbsp;
								<a href="/login" target="new" className="menuLink">
									silubi.io
								</a>
							</div>

							<div className="menuSeparator"></div>

							<span className="menuBoldText">Learn more at</span>
							<br />
							<a href="https://www.silubi.com" target="new" className="menuLink">
								silubi.com
							</a>
						</div>
						<div className="menuFooter">v1.2</div>
					</div>
				)}

				<div
					className="menuObscurer"
					style={menuMask}
					onClick={() => {
						setMenuControl(menuClose);
						setMenuMask(menuMaskOff);
					}}
				></div>
			</div>
		</Fragment>
	);
}
