import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import QrReader from "react-qr-reader";
import useSound from "use-sound";
import beep from "../../Assets/sounds/scanner_beep.mp3";
import { ScannerContext } from "../../Store/ScanerContext";
import { Redirect } from "react-router-dom";

const previewStyle = {
	// height: 240,
	width: 320,
};
const regexp = /^[0-9\b]+$/;
const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

export default function Scanner({ fromTrace, fromScanResult }) {
	const history = useHistory();
	const { isScanning, setIsScanning, scannedItems, setScannedItems, camError, setCamError } = useContext(ScannerContext);
	const [playBeep] = useSound(beep, { volume: 0.25 });

	//******** Setting State ********
	const [qrcodeValue, setQrcodeValue] = useState("");
	const [state, setState] = useState({
		delay: 10000,
		result: "No result",
	});
	const [error, setError] = useState(null);

	//******** Functions ********
	function handleChange(e) {
		if (regexp.test(e.target.value) || regexExp.test(e.target.value) || e.target.value === "") setQrcodeValue(e.target.value);
	}
	function handleSubmitCode() {
		if (!scannedItems.find((x) => x.includes(qrcodeValue))) {
			if (regexExp.test(qrcodeValue))
				setScannedItems((scannedItem) => scannedItem.concat(`https://dev2.silubi.io/prov-wrt/provapi/resources/goods/p/lot/1629220988718/${qrcodeValue}`));
			if (regexp.test(qrcodeValue))
				setScannedItems((scannedItem) => scannedItem.concat(`https://dev2.silubi.io/prov-wrt/provapi/resources/goods/p/g/1629220988718/${qrcodeValue}`));
			setQrcodeValue("");
			setError(null);
			if (fromTrace) {
				history.push(`/trace?gSerial=https://dev2.silubi.io/prov-wrt/provapi/resources/goods/p/g/1629220988718/${qrcodeValue}`);
				setIsScanning(false);
			}
		} else {
			setError("Sorry,you have already scanned this item");
		}
	}

	function handleScan(data) {
		if (data !== null) {
			let entry = data.split("=")[1];
			if (!entry) entry = data;
			playBeep();
			if (fromTrace) {
				history.push(`/trace?gSerial=${entry}`);
			}
			setState({ ...state, result: entry });
			if (scannedItems.find((x) => x === entry)) {
				setError("You have already scanned this item.");
			} else if (!entry.includes("https://dev2.silubi.io/prov-wrt/provapi/resources/goods/")) {
				setError(<p>Only silubi.io barcodes are supported at this time.</p>);
			} else {
				if (scannedItems) {
					setScannedItems((scannedItem) => scannedItem.concat(entry));
					setError(null);
				} else {
					setScannedItems((scannedItem) => scannedItem.concat(entry));
					setError(null);
					setIsScanning(false);
				}
			}
		}
	}
	function handleError() {
		setCamError(true);
	}
	function handleClear(e) {
		setScannedItems(scannedItems.filter((f) => f !== e));
	}
	function handleSubmit() {
		setIsScanning(false);
		history.push(`/scanresult`);
	}
	function handleClose() {
		setIsScanning(false);
		if (!fromScanResult) setScannedItems([]);
		setError(null);
	}

	if (isScanning)
		return (
			<>
				<div className="messaging">Looking for Silubi&trade; QR Code...</div>

				{camError ? (
					<div className="scanner-area">
						<div className="button">
							<div
								className="main-button"
								onClick={() => {
									setCamError(false);
								}}
							>
								<div className="error-ring">
									<span className="material-icons">no_photography</span>
								</div>
							</div>
							<span className="main-button-title">Camera Error!</span>
						</div>
					</div>
				) : (
					<>
						{error ? (
							<>
								<div className="scanner-area">
									<div className="button">
										<div
											className="main-button"
											onClick={() => {
												setError(null);
												setState({ ...state, result: null });
											}}
										>
											<div className="ring">
												<span className="material-icons">qr_code_scanner</span>
											</div>
										</div>
										<span className="main-button-title">Scan Again</span>
									</div>
								</div>
								<div className="result-vis">
									<span className="orange-text">{error}</span>
								</div>
							</>
						) : (
							<>
								<div className="scanner-area">
									<QrReader
										delay={state.delay}
										style={previewStyle}
										onError={() => {
											handleError();
										}}
										onScan={(e) => {
											handleScan(e);
										}}
									/>
									<form className="manualCodeForm">
										<input value={qrcodeValue} placeholder="Manual Input" variant="outlined" onChange={handleChange} />
										<button disabled={qrcodeValue !== "" ? false : true} onClick={handleSubmitCode} size="small">
											<div className="option-button-ring">Search</div>
										</button>
									</form>
									<div className="button">
										<div className="mini-button" onClick={handleClose}>
											<div className="mini-ring">
												<span className="material-icons-round">close</span>
											</div>
										</div>
									</div>
								</div>

								<div className="result-vis">
									{scannedItems.length > 0 && !fromTrace && (
										<>
											{scannedItems.map((item) => (
												<div className="scnnedItem" key={item}>
													<span className="scanRef">{item}</span>
													<span className="inventoryCloser">
														<span
															className="material-icons-round"
															onClick={() => {
																handleClear(item);
															}}
														>
															disabled_by_default
														</span>
													</span>
												</div>
											))}

											<div className="option-button" onClick={handleSubmit} variant="contained">
												<div className="option-button-ring">Submit</div>
											</div>
										</>
									)}
								</div>
							</>
						)}
					</>
				)}
			</>
		);
}
